import Slider, { SwipeDirection } from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick-dots.css"

import TetrisIcon from "../assets/icons/games/tetris-icon-1024.png"
import BlackjackIcon from "../assets/icons/games/blackjack-icon-1024.png"
import SolitaireIcon from "../assets/icons/games/solitaire-icon-1024.png"
import CutAndSliceIcon from "../assets/icons/games/cut-and-slice-icon-1024.png"
import FeedTheSnakeIcon from "../assets/icons/games/feed-the-snake-icon-1024.png"
import KnivesOutIcon from "../assets/icons/games/knives-out-icon-1024.png"
import MonsterMatchIcon from "../assets/icons/games/monster-match-icon-1024.png"
import GolfMagicIcon from "../assets/icons/games/golf-magic-icon-1024.png"
import SpaceEscapeIcon from "../assets/icons/games/space-escape-icon-1024.png"
import WatchTheCapIcon from "../assets/icons/games/watch-the-cup-icon-1024.png"

import SaveTheFlyIcon from "../assets/icons/games/save-the-fly-icon-1024.png"
import FingerballIcon from "../assets/icons/games/fingerball-icon-1024.png"
import DroppinIcon from "../assets/icons/games/droppin-icon-1024.png"
import CoinSwiperIcon from "../assets/icons/games/coin-swiper-icon-1024.png"


import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { GameName, GameRoutePath, GameRoutePathByName } from '../enums/game-name.enum';
import { useNavigate } from 'react-router-dom';

interface IGame {
  name: GameName;
  description: string;
  image: string;
};

const gamesList: IGame[] = [
  {
    name: GameName.Tetris,
    description: "Tetris is a timeless puzzle game where players arrange falling blocks (tetrominoes) to create complete lines, which then clear to score points. With increasing speed and strategic challenges, Tetris offers addictive gameplay that sharpens reflexes, spatial reasoning, and problem-solving skills.",
    image: TetrisIcon
  },
  {
    name: GameName.Blackjack,
    description: "Blackjack is a classic card game where players aim to reach a hand value of 21 without exceeding it. Combining strategy and luck, players compete against the dealer to get the highest total, making it a thrilling choice for casino experience enthusiasts.",
    image: BlackjackIcon
  },
  {
    name: GameName.Solitaire,
    description: "Solitaire is a relaxing single-player card game focused on organizing shuffled cards into sequential stacks by suit. With a mix of patience and strategy, it’s perfect for honing focus and unwinding, offering endless hours of satisfying, solo gameplay.",
    image: SolitaireIcon,
  },
  {
    name: GameName.CutAndSlice,
    description: "Cut and Slice is an action-packed game where players swipe their way through flying fruits, slicing them with precision to earn points. With vibrant visuals and increasing speed, Cut and Slice offers addictive, skill-based gameplay that sharpens reflexes and focus in a fun, juicy challenge.",
    image: CutAndSliceIcon,
  },
  {
    name: GameName.FeedTheSnake,
    description: "As the name suggests, the game offers fun and exciting ways to feed the snake! choose if you want it to go up or down. And on the way, the snake must grab food packages that match its color. But beware, the snake changes colors!",
    image: FeedTheSnakeIcon,
  },
  {
    name: GameName.KnivesOut,
    description: "Knives out is an exciting precision game where players aim to throw knives into a rotating wooden target, hitting specific spots to score points. With each level bringing new speed and patterns, Knives out challenges accuracy, timing, and skill for a sharp, satisfying experience.",
    image: KnivesOutIcon,
  },
  {
    name: GameName.MonsterMatch,
    description: "Monster Match is a colorful puzzle game where blocks with playful monster faces drop from above. Match the colors to make them disappear, score points, and clear the board. With increasing speed and unique patterns, Monster Match sharpens reflexes and strategic thinking in a fun, lively challenge.",
    image: MonsterMatchIcon,
  },
  {
    name: GameName.GolfMagic,
    description: "Golf Magic is a captivating mobile game where players tackle changing courses with varying elevations and distances to the hole. Adjust aim and power to sink each shot with precision. With simple controls and enchanting landscapes, Golf Magic makes for a fun, relaxing test of skill.",
    image: GolfMagicIcon,
  },
  {
    name: GameName.SpaceEscape,
    description: "Space Escape is a thrilling space adventure where players pilot a small spaceship through the galaxy, collecting powerful weapons to destroy planets and obstacles in their path. With dynamic controls and intense action, Space Escape challenges reflexes and strategy in an interstellar journey for survival.",
    image: SpaceEscapeIcon,
  },
  {
    name: GameName.WatchTheCup,
    description: "Watch the Cup is a classic concentration game where players track a small ball hidden under moving cups. As the cups shuffle faster, players must stay focused to guess the ball’s location correctly. With increasing speed and tricky patterns, Watch the Cup sharpens focus and observation skills.",
    image: WatchTheCapIcon,
  },
  {
    name: GameName.SaveTheFly,
    description: "Save the Fly is a thrilling survival game where players tap to keep a tiny fly airborne while dodging deadly timed lasers. Precision and quick reflexes are key to avoiding danger and staying in flight. Simple to play, but get tougher each round, Save the Fly is a great test of skill!",
    image: SaveTheFlyIcon,
  },
  {
    name: GameName.Fingerball,
    description: "Fingerball is an exciting arcade game where players control a single pin to hit a basketball and launch it into a moving basket. With precise timing and skillful shots, every play is a test of reflexes and accuracy. Fast-paced and fun, Fingerball brings a fresh twist to classic pinball action!",
    image: FingerballIcon,
  },
  {
    name: GameName.Droppin,
    description: "Droppin is a satisfying puzzle game where players pull pins to release colorful balls, painting all the grey ones as they fall. Strategically make each drop to ensure every ball is fully colored before reaching the bottom. Simple to play, yet challenging to master, Droppin is a fun test of logic and precision!",
    image: DroppinIcon,
  },
  {
    name: GameName.CoinSwiper,
    description: "Coin Swiper is a fast-paced reflex game where players swipe left or right to sort a rising stack of coins by color. With increasing speed and challenging patterns, quick decisions are key to mastering the game. Test your focus and reaction time in this exciting coin-sorting challenge!",
    image: CoinSwiperIcon,
  },
];

const DesktopGameView = ({ game, index, onClick }: { game: IGame, index: number, onClick: React.EventHandler<React.MouseEvent> }) => {
  const imageOnLeftSide = index % 2 === 0;

  return (
    <div
      key={index}
      className={`game-item flex items-center gap-12 pb-10 ${
        imageOnLeftSide ? '' : 'justify-between'
      }`}
    >
      {imageOnLeftSide && (
        <img src={game.image} alt={game.name} onClick={onClick} className="w-52 h-52 cursor-pointer" />
      )}

      <div className="game-info">
        <h2 className="font-['Baloo_2'] text-[2rem] text-white uppercase font-[500] mb-2">
          {game.name}
        </h2>
        <p className="font-['Baloo_2'] text-white text-lg max-w-2xl">
          {game.description}
        </p>
      </div>

      {!imageOnLeftSide && (
        <img src={game.image} alt={game.name} onClick={onClick} className="w-52 h-52 cursor-pointer" />
      )}
    </div>
  )
};


const MobileGameView = ({ game, onClick }: { game: IGame, onClick: React.EventHandler<React.MouseEvent> }) => {
  return (
    <div
      className='game-item flex flex-col items-center justify-center mb-5'
    >
      <div className="game-info">
        <h2 className="font-['Baloo_2'] text-[2rem] text-white uppercase font-[500] mb-2 text-center">
          {game.name}
        </h2>
        <p className="font-['Baloo_2'] text-white text-lg max-w-2xl p-3 text-center">
          {game.description}
        </p>
      </div>

      <img
        className="mt-5 w-80 h-80 cursor-pointer"
        src={game.image}
        alt={game.name}
        onClick={onClick}
      />
    </div>

  )
};

// Custom arrow components
const NextArrow = ({ className, onClick }: { className: string, onClick: React.EventHandler<React.MouseEvent>}) => (
  <button
    className={`${className} !text-white`}
    onClick={onClick}
    aria-label="Next slide"
  >
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24" 
      stroke="currentColor" 
      className="w-6 h-6"
    >
      <path 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth={2} 
        d="M9 5l7 7-7 7" 
      />
    </svg>
  </button>
);

const PrevArrow = ({ className, onClick }: { className: string, onClick: React.EventHandler<React.MouseEvent>}) => (
  <button
    className={`${className} !text-white`}
    onClick={onClick}
    aria-label="Previous slide"
  >
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24" 
      stroke="currentColor" 
      className="w-6 h-6"
    >
      <path 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth={2} 
        d="M15 19l-7-7 7-7" 
      />
    </svg>
  </button>
);

export const GamesSection = () => {
  const navigate = useNavigate();
  const sliderRef = useRef<Slider>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevious = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const openGame = (name: GameName) => {
    navigate(GameRoutePathByName[name]);
  };

  return (
    <div className='h-full w-full bg-blazefun-navy flex justify-center lg:p-16 xs:p-6 xs:pb-16'>
      <div className='container w-[53rem]'>
        <h2 className="font-['Baloo_2'] font-[400] uppercase text-[2rem] text-white text-center mt-2 mb-6">Explore our games</h2>

        <div className='xs:hidden lg:block desktop-games-container'>
          {
            gamesList.map((game, index) => (
              <DesktopGameView key={index} game={game} onClick={() => openGame(game.name)} index={index}/>
            ))
          }
        </div>

        <div className='mobile-games-container lg:hidden xs:block'>
          <Slider
            ref={sliderRef}
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            dotsClass='slick-dots-custom'
            adaptiveHeight={true}
            appendDots={(dots: React.ReactElement<React.HTMLAttributes<HTMLLIElement>, 'li'>[]) => {
              return (
                <div className='slick-dots'>
                  <div className='flex'>
                    <PrevArrow className="" onClick={handlePrevious} />
                    <div>
                      {dots}
                    </div>
                    <NextArrow className="" onClick={handleNext}/>  
                  </div>
                </div>
              )}
            }
            customPaging={(i) => {
              return (
                <div key={i} className={`w-3 h-3 mx-1 rounded-full transition-all duration-300 
                  ${i === currentSlide ? 'bg-orange-500' : 'bg-gray-300 hover:bg-gray-400'}
                `}
                />
              )
            }}
            beforeChange={(oldIndex, newIndex) => {
              setCurrentSlide(newIndex)
            }}
            arrows={false}
          >
            {
              gamesList.map((game, index) => (
                <MobileGameView key={index} game={game} onClick={() => openGame(game.name)} />
              ))
            }
          </Slider>
        </div>

        <ins className="adsbygoogle"
          style={{ display: 'inline-block', height: "120px" }}
          data-ad-client="ca-pub-2832996518227049"
          data-ad-slot="9174693227"
          data-ad-format="auto"
          data-full-width-responsive="true">
        </ins>
        <script>
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        </script>
      </div>
    </div>
  );
};